


export default function NotFound(){
    return(
        <>
            <h1>
                Page Not Found
            </h1>
        </>
    )
}